<template>
  <div
    class="messageSwitch"
    v-watermark
  >
    <!-- 顶部导航 -->
    <commonNavBar :title="'消息提醒'"></commonNavBar>
    <div
      class="infoBox"
      v-watermark
    >
      <van-cell-group>
        <van-cell
          title="消息提醒:"
          center
        >
          <template #default>
            <van-switch
              v-model="profile.switch.messageNotice"
              size="20"
              :loading="messageLoading"
              @click="messageSwitchClicked"
            />
          </template>
        </van-cell>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { userChangeSwitch } from '@/api/user'

export default {
  name: 'messageSwitch',
  data() {
    return {
      messageLoading: false

    }
  },
  computed: {
    ...mapState('user', ['profile'])
  },
  methods: {
    ...mapActions('user', ['getUserProfile']),
    // 消息开关点击
    async messageSwitchClicked() {
      if (!this.messageLoading) {
        this.messageLoading = true
        try {
          await userChangeSwitch({ key: 'message', value: !this.profile.switch.messageNotice })
          await this.getUserProfile()
          this.messageLoading = false
        } catch (error) {
          this.messageLoading = false
          this.$toast.fail({
            message: '操作失败,请稍后再试!',
            duration: 500
          })
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.messageSwitch {
  ::v-deep {
    .infoBox {
      height: calc(100vh - 46px);
      position: fixed;
      width: 100%;
      overflow: auto;

      .van-cell-group {
        .van-cell {
          .van-cell__value {
            .van-switch {
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}
</style>
